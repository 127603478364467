import React, { useEffect, useRef, useState } from 'react'
import './LocationAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import LocationData from './LocationData'
import Review from '../../Images/Review.png'
import locationpin from '../../Images/locationpin.png'

//
import GoogleMapReact from "google-map-react";
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';

export default function LocationAll() {
    const mapRef = useRef(null);
    const navigate = useNavigate()
    // 


    return (
        <div className="LocationAll">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Santa Clarita Garage Door | Our Garage Door Locations</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Santa Clarita Garage Door | Our Locations" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Santa Clarita Garage Door | Our Locations" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.santaclaritagaragedoor.org/locations" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Discover Our Locations At Santa Clarita Garage Door Serving | Garage Door Repair in Santa Clarita, CA area and surrounding cities." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Discover Our Locations At Santa Clarita Garage Door Serving | Garage Door Repair in Santa Clarita, CA area and surrounding cities." data-react-helmet="true" />
                <meta name="keywords" content="Garage Dooring, Garage Door, Garage Doorinspection, Garage Door repair, Garage Door maintenance, Creosote removal, Garage Doorsafety, Garage Doorflue Repair, Soot removal, Garage Door Maintenance Locations" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Santa Clarita Garage Door" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Additional Santa Clarita Garage door Locations</h1>
                    <h2>Welcome to Santa Clarita's Premier Garage Door Service Provider</h2>
                    <span>
                        <a href="tel:661-402-9107">CALL 661-402-9107<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <span className="GoogleButt">
                        <img src={Google} alt="Santa Clarita Garage Door Reviews" />
                        <h6>4.9 Stars - Based on 1,583 Reviews</h6>
                    </span>
                </div>

            </div>

            <div className="LocationAllListMap">
                <div className="LocationAllLsit">
                    <div className="LocationAllBox1 LocationAllBox">
                        <div className="LocationAllBoxLeft">
                            <h2>Santa Clarita, CA</h2>
                            <h3>Open Now 24/7 • Free Estimates • Same Day Service</h3>
                            <h4>Serving Garage Door Repair in Santa Clarita, CA area and surrounding cities.</h4>
                            <h5><a href='/'>VISIT THIS LOCATION</a> - <a href={`/services`}>SEE SERVICES</a></h5>

                            <span>
                                <a href={`tel:661-402-9107`}>
                                    <img src={[telephone]} alt={`661-402-9107 - Santa Clarita Garage Door`} />661-402-9107
                                </a>
                                <a href={`/book`}>
                                    <img src={[book]} alt={`SCHEDULE ONLINE Santa Clarita Garage Door`} />SCHEDULE ONLINE
                                </a>
                                <a href={`/reviews/${LocationData.find(a=>a.City == ("Santa Clarita").replace("%20",'')).City.replace(" ",'').toLowerCase()}`} >
                                    <img src={[locationpin]} alt={`Read reviews of Santa Clarita Garage Door`} />READ REVIEWS
                                </a>
                            </span>
                        </div>
                    </div>
                    {LocationData.map((A, index) =>
                        <div key={index} className="Header1BoxMenuBox">
                            <div className="LocationAllBox">
                                <div className="LocationAllBoxLeft">
                                    <h2>{A.City}, {A.State}</h2>
                                    <h3>Open Now 24/7 • Free Estimates • Same Day Service</h3>
                                    <h4>Serving Garage Door in {A.City}, CA area and surrounding cities.</h4>
                                    <h5><a href={A.Url} target='_blank'>VISIT THIS LOCATION</a> - <a href={`${A.Url}/services`} target='_blank'>SEE SERVICES</a></h5>
                                    <span>
                                        <a href={`tel:${A.Phone}`}>
                                            <img src={[telephone]} alt={`${A.Phone} - ${A.City} Garage Door`} />{A.Phone}
                                        </a>

                                        <a href={`${A.Url}/book`}>
                                            <img src={[book]} alt={`SCHEDULE ONLINE ${A.City} Garage Door`} />SCHEDULE ONLINE
                                        </a>

                                        <a href={`/reviews/${A.City.replace(' ','').toLowerCase()}`} >
                                            <img src={[locationpin]} alt={`Read reviews of ${A.City} Garage Door`} />READ REVIEWS
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

